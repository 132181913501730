<template>
  <div>
    <el-card>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item><a @click="request">首页</a></el-breadcrumb-item>
        <el-breadcrumb-item :to="{path: '/newpolicy'}">最新政策</el-breadcrumb-item>
        <el-breadcrumb-item>添加政策</el-breadcrumb-item>
      </el-breadcrumb>

      <el-form ref="form" :model="form" label-width="80px" class="el-form" @submit.native.prevent>
        <el-form-item label="政策标题">
          <el-input v-model="form.title" placement="请输入政策标题"></el-input>
        </el-form-item>
        <el-form-item label="政策内容">
          <editor ref="editor"/>
        </el-form-item>
      </el-form>
      <el-row>
        <el-col :span="3" :offset="9">
          <el-button type="primary" @click="requestCreate">确定</el-button>
        </el-col>
        <el-col :span="3">
          <el-button type="info" @click="requestCancel">取消</el-button>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>

import editor from "../../../components/editor"

export default {
  data() {
    return {
      form: {
        columnId: '',
        title: '',
        content: '',
      }
    }
  },
  methods: {
    request() {
      let newPage = this.$router.resolve({
        name: 'EntryStoma',
      })
      window.open(newPage.href, '_blank')
    },
    getData() {
      let self = this;
      this.$axios.get('/column/page').then(response => {
        if (response.data.status === 200) {
          response.data.data.records.forEach(Array => {
            if (Array.name === '最新政策') {
              self.form.columnId = Array.id
            }
          })
        } else {
          self.$root.warn("数据加载失败" + response.data.status);
        }
        self.total = response.data.data.total;
      }).catch(error => {
        console.error(error);
      })
    },

    requestCreate() {
      this.form.content = this.$refs.editor.myValue;
      let self = this;
      if (self .form.title.length <= 0) {
        self.$root.warn("请输入政策标题", "");
        return;
      }
      if (self.form.content.length <= 0) {
        self.$root.warn("请输政策入内容", "");
        return;
      }
      self .$axios.post("/article", self.form).then(response => {
        if (response.data.status === 200) {
          self.$root.success('添加成功');
          self.form = {};
          self.$router.push({name: 'newpolicy'})
        } else {
          self .$root.warn('添加失败');
        }
      }).catch(error => {
        console.error(error)
      })
    },

    requestCancel() {
      this.$router.back()
    }
  },
  components: {
    editor
  },
  created() {
    this.getData()
  }
}
</script>

<style scoped>
.el-form {
  margin: 32px 32px 0;
}
</style>